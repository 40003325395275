<template>
  <b-card>
    <b-card-title v-if="$store.getters['settings/getDebug']">
      {{ completeurl }}
      {{ cookie }}
    </b-card-title>
    <iframe
      class="iframe"
      id="legacy_gui_isp"
      name="legacy_gui_isp"
      :class="classname"
      ref="iframe"
    />
    <!-- <iframe class="iframe" id="legacy_gui_isp" name="legacy_gui_isp" :class="classname" 
                            referrerpolicy="origin-when-cross-origin" sandbox="allow-popups allow-forms allow-scripts" ref="iframe"/> -->
  </b-card>
</template>

<script>
var CryptoJS = require("crypto-js");

export default {
  name: "reverse-proxy-frame",
  props: {
    url: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      default: "components",
    },
    path: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      default: null,
    },
    classname: {
      type: String,
    },
    service: {
      type: String,
    },
    debug: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    preroute() {
      if (this.$attrs.matched == null)
        this.$route.replace({ name: "Account Management / Accounts" });
      return this.$attrs.matched[this.$attrs.matched.length - 1].path;
    },
    getQuery() {
      return "?" + this.getQueryAdd() + (this.query ? "&" + this.query : "");
    },
    getQueryAdd() {
      return (
        "LANG=" +
        this.lang +
        "&h_currsrvc=" +
        this.currentService +
        "&h_csrf=" +
        this.generateCSRFToken() +
        "&h_lgnpwd=panel"
      );
    },
    generateCSRFToken() {
      let time = Math.floor(new Date().getTime() / 1000);
      let msg = this.$store.getters["auth/id"] + time;
      let encrypted = CryptoJS.HmacSHA1(msg, "M4n!sht4n4");
      return (
        "" +
        time +
        "$$$" +
        encodeURIComponent(CryptoJS.enc.Base64.stringify(encrypted))
      );
    },
    updateCookie(newValue) {
      this.$cookie.set("OPT_OWS_ISP", newValue, {
        expires: 100 /*domain: window.location.hostname*/,
      });
    },
  },
  computed: {
    cookie: function () {
      return this.$store.getters["auth/cookie"];
    },
    completeurl() {
      return "/" + this.prefix + this.path + this.url + this.getQuery();
    },
    lang() {
      return "eng";
    },
    currentService() {
      if (this.service) return this.service;
      if (this.path) {
        switch (this.path.split("/")[1]) {
          case "General":
            return "GEN";
          case "Firewall":
            if (this.path.split("/")[2] == "WebProxy") return "WB";
            else return "FZ";
          case "AdsFree":
            return "AZ";
          case "WebFilter":
            return "WZ";
          case "Activation":
            return "TZ";
          case "AntiPhishing":
            return "PZ";
          case "Antivirus":
            return "VZ";
          case "AntiBotnet":
            return "BZ";
        }
      }

      return "";
    },
  },
  mounted() {
    if (this.$store.getters['settings/isIOT']) {
      this.$nextTick(() => {
        this.$router.push({ path: "/" });
      });
    } else {
      this.$store.commit("settings/startLoading", true);
      this.updateCookie(this.cookie);
      setTimeout(() => {
        this.$refs.iframe.src = this.completeurl;
        this.$refs.iframe.onload = () => {
          console.log("loading " + this.completeurl + "...");
        };
        this.$store.commit("settings/finishLoading", true);
      }, 500);
    }
  },
  // watch: {
  //     cookie(newValue, oldValue){
  //         this.updateCookie( newValue)
  //     }
  // }
};
</script>

<style lang="scss" scoped>
.iframe {
  margin-top: 20px;
  height: calc(100vh - 95px);
  overflow-y: scroll;
  width: 100%;
  border: 0px;
}
</style>
